.background {
  background-image:
    url('../images/Baseball.png'),
    url('../images/Background.png');
  background-repeat: no-repeat;
  background-position:
    left 6% top 168px,
    0, 0;
  background-size:
    182px 353px;
  height: 100vh;
}