body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image:
    url('./assets/images/Baseball.png'),
    url('./assets/images/Basketball.png'),
    url('./assets/images/Hockey.png'),
    url('./assets/images/Background.png');
  background-repeat: no-repeat;
  background-position:
    left 1% top 0px,
    right 1% top 0px ,
    right 1% top 100%,
    0, 0;
  background-size:
    100% 100%,
    33% 55%,
    100% 50%,
    cover;
  min-height: 100vh;
}

@media (max-width: 768px) {
  body {
    background-position:
      left 1% top 0px,
      right -20% top 0px,
      right 1% top 100%, /* Adjusted for smaller screens */
      0 0;
    background-size:
      100% 100%, /* Adjusted for smaller screens */
      70% 60%, /* Adjusted for smaller screens */
      200% 36%, /* Adjusted for smaller screens */
      cover;
      min-height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {  body {
    background-position:
      left 1% top 0px,
      right -20% top 0px,
      right 1% top 100%, /* Adjusted for smaller screens */
      0 0;
    background-size:
      100% 100%, /* Adjusted for smaller screens */
      70% 60%, /* Adjusted for smaller screens */
      200% 40%, /* Adjusted for smaller screens */
      cover;
      min-height: 100vh;
  }
}


