.home-container {
    display: flex;
    flex-direction: column;
}

.logo-container {
    margin-top: 99px;
    display: flex;
    justify-content: center;
    margin-left: 116px;

    @media (max-width: 768px) {
        margin-left: 0px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 64px;
    }
}

.container {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    /* Responsive adjustments */
    @media (max-width: 768px) {
        margin-top: 50px;
        margin-left: 20px;
        flex-direction: row;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        margin-top: 50px;
        margin-left: 100px;
        flex-direction: row;
        margin-right: 100px;
    }


}

.icons {
    display: flex;
    flex-direction: column;
    margin-top: 2px;

    img {
        height: 112px;
        margin-bottom: 34px;
    }

    @media (max-width: 768px) {
        img {
            margin-bottom: 10px;
            height: 50px;

        }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        img {
            margin-bottom: 20px;
            height: 80px;
        }
    }


}

.content {
    max-width: 800px;
    margin-left: 60px;
    color: white;

    /* Responsive adjustments */
    @media (max-width: 768px) {
        margin-left: 10px;
        width: 100%;
        max-width: none;
        margin-right: 22px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-right: 80px;
    }

}

.contactus {
    margin-top: 34px;

    @media (max-width: 768px) {
        margin-top: 24px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-top: 50px;
    }
}

.contactus-text {
    margin-top: 10px;
}

.responsive-logo {
    width: 700px;
    height: auto;

    @media (max-width: 767px) {
        width: 89%;
        height: auto;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 550px;
        margin-top: 60px;
        height: auto;
    }
}

.footer {
    margin-top: 20px;

    @media (max-width: 767px) {
        margin-top: 20px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-top: 30px;

    }
}

a:link {
    color: #00aeef;
    text-decoration: none;
}

a:visited {
    color: #00aeef;
    text-decoration: none;

}

a:hover {
    color: #00aeef;
    text-decoration: none;

}

a:active {
    color: #00aeef;
    text-decoration: none;

}