.pn-b {
    font-family: 'proxima-nova', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #00aeef;


    @media (max-width: 768px) {
        font-size: 15px;
    }
}

.pn-m {
    font-family: 'proxima-nova', sans-serif;
    font-size: 18px;
    display: inline;

    @media (max-width: 768px) {
        font-size: 15px;
        display: inline
    }
}

.pn-m-p {
    font-family: 'proxima-nova', sans-serif;
    font-size: 18px;

    @media (max-width: 768px) {
        font-size: 15px;
    }
}


.es-m {
    font-family: 'eurostile-extended', sans-serif;
    font-size: 48px;
    color: #00aeef;

    @media (max-width: 768px) {
        font-size: 20px;

    }

}

.es-b {
    font-family: 'eurostile-extended', sans-serif;
    font-weight: bold;
    font-size: 48px;
    color: #00aeef;
    margin-left: 6px;

    @media (max-width: 768px) {
        font-size: 20px;
        margin-left: 2px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 5px;
    }
}

.es-l {
    font-family: 'eurostile-extended', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: white;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.es-l-b {
    font-family: 'eurostile-extended', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #00aeef;

    @media (max-width: 768px) {
        font-size: 10px;
    }
}