.footer {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 20px;
      padding: 0px;

  }

  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  .footer-link {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    font-family: 'eurostile-extended', sans-serif;
    font-weight: 600;
    font-size: 18px;
  }

.footer-rights {
    font-family: 'eurostile-extended', sans-serif;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
  }
  @media (max-width: 768px) {
    .footer-links {
      align-items: center;
      margin-bottom: 0px;

    }
  
    .footer-link {
      margin-bottom: 10px;
      font-family: 'eurostile-extended', sans-serif;
      font-weight: 600;
      font-size: 12px;
    }
    .footer-rights {
        font-family: 'eurostile-extended', sans-serif;
        font-weight: 600;
        font-size: 8px;
        text-align: center;
        margin-bottom: 20px;
      }
  }