.terms-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
}
.terms-text{
    font-family: 'proxima-nova', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: white;
    margin-top: 99px;
    max-width: 800px;
    max-height: 800px;
    overflow:auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;

}
.terms-title{
    font-family: 'eurostile-extended', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: center;
}